/** @format */

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";

// pick a date util library
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import theme from "./config/theme";
import Home from "./pages/home/indexHome";
import Contact from "./pages/contact/indexContact";
import Task from "./pages/task/indexTask/index";

import Lead from "./pages/lead/indexLead";
import Register from "./pages/user/register";
import Deal from "./pages/deal/indexDeal";
import Reports from "./pages/reports/indexReports";
import CompanyAuth from "./pages/user/companyAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsRoutes from "./pages/settings/settingsRoutes";
import Profile from "./pages/profile";
import NotFound from "./components/Layout/404";
import Account from "./pages/account/indexAccount";
import Calendar from "./pages/calendar";
import Communication from "./pages/communication";
// import Task from "./pages/task";
import Roles from "./pages/roles";
import Admin from "./pages/admin";
const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/register' element={<Register />} />
          <Route path='/admin-panel' element={<Admin />} />
          <Route path='/my-crm/*' element={<CompanyAuth />} />
          <Route path='/leads' element={<Lead />} />
          <Route path='/accounts/*' element={<Account />} />
          <Route path='/contacts/*' element={<Contact />} />
          <Route path='/task/*' element={<Task />} />

          <Route path='/deals/*' element={<Deal />} />
          <Route path='/reports/*' element={<Reports />} />
          <Route path='/settings/*' element={<SettingsRoutes />} />
          <Route path='/user/*' element={<Profile />} />
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/communication' element={<Communication />} />
          {/* <Route path='/task' element={<Task />} /> */}
          <Route path='/roles' element={<Roles />} />
          <Route
            path='/subscription-expired'
            element={<h1>subscription expired</h1>}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <ToastContainer
          position='bottom-center'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
