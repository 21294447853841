import { useQuery } from "react-query"; 
import { toast } from "react-toastify"; 
import { useEffect, useState } from "react"; 
import { Device } from '@twilio/voice-sdk'; 
import axios from "axios";
import getDomain from "../../utils/getDomain";
import { CallToast } from "./CallToast ";

const useTwilioVoiceCall = (phoneNumber) => {
    const [device, setDevice] = useState(null);
    const [callDuration, setCallDuration] = useState(0);
    const [callStartTime, setCallStartTime] = useState(0);
    const [intervalId, setIntervalId] = useState(null); 
    const [isConnecting, setIsConnecting] = useState(true);
    const user = JSON.parse(localStorage.getItem("userData"));
    const options = {
        iceServers: [
            { urls: 'stun:stun.twilio.com:3478' },
            {
                urls: 'turn:turn.twilio.com:3478',
                username: 'your-turn-username',
                credential: 'your-turn-credential'
            }
        ]
    };

    const fetchTwilioToken = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
                process.env.REACT_APP_API_URL
            }/tportal/get-call-token/`,
            {
                headers: {
                    "x-access-token": `${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.data.token) {
            throw new Error("Error fetching Twilio token.");
        }
        return response.data.token;
    };

    const { refetch } = useQuery("get-call-token", fetchTwilioToken, {
        enabled: false,
        onSuccess: (token) => {
            const twilioDevice = new Device(token, {
                enableImprovedSignalingErrorPrecision: true,
            });

            setDevice(twilioDevice);
            setupDeviceListeners(twilioDevice);
        },
        onError: (error) => {
            console.error("Error fetching Twilio token:", error);
        },
    });

    const setupDeviceListeners = (twilioDevice) => {
        twilioDevice.on("connect", () => {
            console.log("Call connected.");
        });

        twilioDevice.on("disconnect", () => {
            console.log("Call disconnected.");
            clearInterval(intervalId); 
            toast.dismiss();
        });
    };

const startTimer = (toastId) => {
    const id = setInterval(() => {
        const totalSeconds = Math.floor((Date.now() - callStartTime) / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedDuration = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

        setCallDuration(totalSeconds);

        toast.update(toastId, {
            render: <CallToast duration={formattedDuration} endCall={endCall} muteCall={muteCall} unmuteCall={unmuteCall} phoneNumber={phoneNumber} />,
        });
    }, 1000);

    setIntervalId(id);
};

const makeCall = async (recipient) => {
    setCallDuration(0);
    setCallStartTime(Date.now()); 

    const toastId = toast.info(
        <CallToast duration="Calling..." endCall={endCall} muteCall={muteCall} unmuteCall={unmuteCall} phoneNumber={phoneNumber} />,
        {
            autoClose: false,
            closeButton: false,
            icon:false,
        }
    );

    if (device) {
        try {
            if (!recipient) {
                console.warn("Recipient number is required.");
                return;
            }

            const connection = await device.connect({
                params: { To: recipient, From: user?.uuid },
                rtcConfiguration: options,
            });

            if (!connection) {
                console.warn("Connection object is not valid.");
                return;
            }

            toast.update(toastId, {
                render: <CallToast duration="Calling..." endCall={endCall} muteCall={muteCall} unmuteCall={unmuteCall} phoneNumber={phoneNumber} />,
            });

            connection.on("accept", () => {
                console.log("Call accepted. Starting timer...");
                startTimer(toastId); 
            });

            connection.on("error", (callError) => {
                console.error("Call error occurred:", callError);
                toast.update(toastId, {
                    render: <div>Error: {callError.message}</div>,
                });
            });

            connection.on("disconnect", () => {
                console.log("Call disconnected.");
                clearInterval(intervalId);
                toast.dismiss(toastId);
            });

        } catch (error) {
            console.error("Error connecting to the call:", error);
        }
    } else {
        console.warn("Device is not initialized.");
    }
};



    const endCall = async () => {
        if (device) {
            await device.disconnectAll();
        }
    };
    const muteCall = () => {
    if (device ) {
        device.mute(true); 
        }
    };

    const unmuteCall = () => {
        if (device ) {
            device.mute(false);
        }
    };

    return { makeCall, endCall, callDuration, refetch };
};

export default useTwilioVoiceCall;
