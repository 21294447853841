import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const SuccessInfo = () => {
  const { url } = useSelector((state) => state.authStore);

  return (
    <Grid container spacing={2} className="pt-4">
      <Grid item xs={12}>
        <Typography fontSize="12px">
          You profile has been successfully registered. <br />
          To resgister your company, please refer link sent on registered email
          and follow further steps. Or in case if you don't receive an email use
          following link :- <br />{" "}
          <a href={url}>{url.replace("http://", "")}</a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SuccessInfo;
