/** @format */

import { useMutation } from "react-query";
import axios from "axios";
import getDomain from "../../utils/getDomain";

const useClickToCall = (phNum) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];

  const clickToCall = async () => {
    const number = phNum.replace(/-/g, "").slice(-10);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
          process.env.REACT_APP_API_URL
        }/leads/twillocall/`,
        { Phone: number },
        {
          headers: {
            "x-access-token": `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error making the API request", error);
      throw error;
    }
  };
  const Mutation = useMutation(clickToCall);
  return { mutate: Mutation.mutateAsync };
};

export default useClickToCall;
