/** @format */

export const menus = [
  { name: "Home", path: "/" },
  { name: "Leads", path: "/leads" },
  { name: "Accounts", path: "/accounts" },
  { name: "Contacts", path: "/contacts" },
  { name: "Deals", path: "/deals" },
  { name: "Calendar", path: "/calendar" },
  { name: "Task", path: "/task" },
  { name: "Communication", path: "/communication" },
  { name: "Reports", path: "/reports" },
  { name: "Roles", path: "/roles" },
  { name: "Settings", path: "/settings" },
];
