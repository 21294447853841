import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs({ data, isHeightAuto, sx, panelClass }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const smMatches = useMediaQuery("(max-width:500px)")

  return (
    <Box
      sx={{ bgcolor: "background.paper", ...sx }}
      style={{ height: isHeightAuto ? "auto" : "calc(100vh - 200px)" }}
      // style = {smMatches ? {height:"calc(100vh - 500px)"} : {height:"auto"}}
    >
      <AppBar
        position="static"
        style={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          >
          {data.map((item) => (
            <Tab
            // smMatches ? sx={{ fontSize: "0.67rem"}} : sx ={{fontSize:"0.87rem"}}
            sx ={ smMatches ? {fontSize: "0.67rem"} : {fontSize:"0.87rem"}}
              
              key={item.index}
              label={item.label}
              {...a11yProps(item.index)}
              className={value === item.index ? "bg-forth first" : ""}
            />
          ))}
        </Tabs>
      </AppBar>

      {data.map((item) => (
        <TabPanel
          key={item.index}
          value={value}
          index={item.index}
          style={{
            width: "100%",
            // maxWidth : "93vw",
            ...(isHeightAuto
              ? {}
              : { height: "calc(100vh - 200px)", overflow: "scroll" }),
            ...(item.styles ? { ...item.styles } : {}),
          }}
          className={panelClass ? panelClass : "bg-forth"}
        >
          <item.component
            style={{ backgroundColor: "red" }}
            {...item.componentProps}
          />
        </TabPanel>
      ))}
    </Box>
  );
}
