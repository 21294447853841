import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../../../components/Layout/Header";
import Navbar from "../../../components/Layout/navbar";
import SideBar from "../../../components/Layout/sidebar";
import PrivateRoute from "../../../utils/PrivateRoute";
import Leads from "../custLeads";
import DealPipeline from "../customize/dealPipeline";
import ImportDataRoutes from "../importData/ImportDataRoutes";
import Products from "../product/indexProduct";
import sideBarMenu from "../sideBarMenu";
import Users from "../user/indexUser";

const SettingsRoutes = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <Header
        name="Settings"
        addButton={<div />}
        customFilter={<div />}
        showSearch={false}
      />
      <SideBar menu={sideBarMenu} />
      <div className="setting-wrapper">
        <Routes>
          <Route path="/" element={<div>Nothing Here</div>} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/products" element={<Products />} />
          <Route path="/users" element={<Users />} />
          <Route path="/deal-pipeline" element={<DealPipeline />} />
          <Route path="/import-data/*" element={<ImportDataRoutes />} />
        </Routes>
      </div>
    </PrivateRoute>
  );
};

export default SettingsRoutes;
