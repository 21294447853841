/** @format */

import React, { useEffect, useRef, useState } from "react";

import Container from "../../../components/Layout/Container";

import Header from "../../../components/Layout/Header";
import Table from "../../../components/Layout/table";

import useTaskTable from "./useTaskTable";
import { useDispatch } from "react-redux";
import { openContactDialog } from "../../../redux/actions/LayoutAction";
import { leadAndContactColumns } from "../../../constants/columns";
import useTaskData from "../taskHooks/useTaskData";

const TaskTable = () => {
  const { createColumnSchema } = useTaskTable();
  const [taskData, setTaskData] = useState([]);
  const { isLoading } = useTaskData({
    onSuccess: (data) => setTaskData(data?.data),
  });

  const tableRef = useRef();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(openContactDialog());
  };

  const handleExportCSV = () => {
    if (tableRef.current) {
      tableRef.current.exportCSV(); // Ensure you have the exportCSV method in Table
    }
  };
  useEffect(() => {
    document.title = "All Contacts | CRM";
  }, []);

  return (
    <>
      <Header
        name='Task'
        showSearch={true}
        exportButton
        exportOnClick={handleExportCSV}
        addBtnClick={handleClickOpen}
      />
      <Container sx={{ marginTop: "30px" }}>
        <Table
          mt={"30px"}
          data={taskData ? taskData : []}
          ref={tableRef}
          loading={isLoading}
          columns={
            taskData && taskData[0]
              ? createColumnSchema(taskData[0])
              : leadAndContactColumns
          }
        />
      </Container>
    </>
  );
};

export default TaskTable;