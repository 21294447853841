import { Close, Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { menus } from "../../../constants/menus";
import getDomain from "../../../utils/getDomain";
import logo from "../../../media/logo.png";
import { IoSearch } from "react-icons/io5";
const MobileNav = ({ handelLogout }) => {
  const [open, setOpen] = useState(false);
  const toggleMenu = () => setOpen((prev) => !prev);
  const { user } = useSelector((state) => state.userStore);

  const { pathname } = useLocation();

  return (
    <nav className='d-flex justify-content-between align-items-center full-width'>
      <IconButton size='small' onClick={toggleMenu}>
        {/* <Avatar
          alt='avatar'
          src={`${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
            process.env.REACT_APP_API_URL
          }${user?.profile?.profile_pic}`}
          sx={{
            width: "32px",
            height: "32px",
          }}
        /> */}
        <MenuRoundedIcon sx={{color:"white !important"}}></MenuRoundedIcon>

      </IconButton>
      <div>
        <IconButton sx={{ color: "#ffffff" }}>
          <IoSearch />
        </IconButton>
        <IconButton>
          <Avatar alt='avatar' src={logo} sx={{ width: 30, height: 30 }} />{" "}
        </IconButton>
      </div>
      <Drawer
        sx={{ "& .MuiPaper-root": { width: "65%", background: "#274c77" } }}
        anchor={"left"}
        open={open}
        onClose={toggleMenu}
      >
        <Box className='bg-first' sx={{ height: "100%" }}>
          <Box
            display='flex'
            justifyContent={"space-between"}
            alignItems='center'
            className='px-2 py-1 border-bottom'
          >
            <Link to='/user/profile' className='d-flex align-items-center'>
              <Avatar
                alt='avatar'
                src={`${
                  process.env.REACT_APP_API_PROTOCOL
                }${getDomain()}${"."}${process.env.REACT_APP_API_URL}${
                  user?.profile?.profile_pic
                }`}
                sx={{
                  width: "32px",
                  height: "32px",
                  marginRight: "15px",
                }}
              />
              <Typography className='text-light'>
                {user?.profile?.full_name}
              </Typography>
            </Link>

            <IconButton onClick={toggleMenu}>
              <Close htmlColor='#fff' />
            </IconButton>
          </Box>

          <Box>
            <List className='py-0' sx={{ height: "100%" }}>
              {menus?.map((item) => (
                <Link
                  to={item.path}
                  className='d-flex justify-content-center align-items-center white navbar-link'
                  style={{
                    background: pathname === item?.path ? "#1b3b60" : "#274c77",
                  }}
                >
                  <ListItem className='py-1'>
                    <ListItemButton>
                      <ListItemText
                        primary={item?.name}
                        className='text-light'
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Box>
          <Box
            sx={{ height: "100px", paddingLeft: "32px" }}
            className='d-flex align-items-end pb-3 justify-content-start'
          >
            <div
              className='d-flex align-items-center'
              style={{ cusror: "pointer" }}
              onClick={handelLogout}
            >
              <IconButton>
                <Logout fontSize='small' htmlColor='#fff' />
              </IconButton>
              <Typography className='text-light'>Logout</Typography>
            </div>
          </Box>
        </Box>
      </Drawer>
    </nav>
  );
};

export default MobileNav;
