/** @format */

import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { setSingleContact } from "../../../redux/actions/contactAction";
import useGetSingleTask from "../taskHooks/useGetSingleTask";

const useSingleContact = (id) => {
  const { data, isLoading } = useGetSingleTask(id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setSingleContact(data.data));
    }
  }, [dispatch, data]);

  return { isLoading };
};

export default useSingleContact;
