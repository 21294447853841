/** @format */

import React, { useEffect, useState, useRef } from "react";
import Container from "../../../components/Layout/Container";
import Header from "../../../components/Layout/Header";
import Table from "../../../components/Layout/table";
import useAccountTable from "./useAccountTable";
import { useDispatch } from "react-redux";
import { openAccountDialog } from "../../../redux/actions/LayoutAction";
import { leadAndContactColumns } from "../../../constants/columns";
import useAccountData from "../accountHooks/useAccountData";

const AccountTable = () => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { createColumnSchema } = useAccountTable();
  const [accountsData, setAccountsData] = useState([]);
  const { isLoading } = useAccountData({
    onSuccess: (data) => setAccountsData(data?.data),
  });

  const handleClickOpen = () => {
    dispatch(openAccountDialog());
  };

  const handleExportCSV = () => {
    if (tableRef.current) {
      tableRef.current.exportCSV();
    }
  };

  useEffect(() => {
    document.title = "All Accounts | CRM";
  }, []);

  return (
    <>
      <Header
        name='Accounts'
        showSearch={true}
        exportButton
        exportOnClick={handleExportCSV}
        addBtnClick={handleClickOpen}
      />
      <Container sx={{ marginTop: "30px" }}>
        <Table
          ref={tableRef} // Pass the ref here
          data={accountsData || []}
          loading={isLoading}
          columns={
            accountsData && accountsData[0]
              ? createColumnSchema(accountsData[0])
              : leadAndContactColumns
          }
        />
      </Container>
    </>
  );
};

export default AccountTable;
