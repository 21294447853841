/** @format */

import React from "react";
import Navbar from "../../components/Layout/navbar";
import CalendarComponent from "../../components/Layout/Calendar/Index";
import Header from "../../components/Layout/Header";

const Calendar = () => {
  const data = [
    {
      title: "Team Meeting",
      start: new Date(2024, 9, 2, 10, 0, 0), // Oct 2, 2024, 10:00 AM
      end: new Date(2024, 9, 2, 11, 0, 0), // Oct 2, 2024, 11:00 AM
      completed: false,
    },
    {
      title: "Project Kickoff",
      start: new Date(2024, 9, 4, 9, 0, 0), // Oct 4, 2024, 9:00 AM
      end: new Date(2024, 9, 4, 10, 30, 0), // Oct 4, 2024, 10:30 AM
      completed: false,
    },
    {
      title: "Conference",
      start: new Date(2024, 9, 6, 8, 0, 0), // Oct 6, 2024, 8:00 AM
      end: new Date(2024, 9, 6, 17, 0, 0), // Oct 6, 2024, 5:00 PM
      completed: false,
    },
    {
      title: "Client Presentation",
      start: new Date(2024, 9, 9, 14, 0, 0), // Oct 9, 2024, 2:00 PM
      end: new Date(2024, 9, 9, 16, 0, 0), // Oct 9, 2024, 4:00 PM
      completed: true,
    },
    {
      title: "Business Lunch",
      start: new Date(2024, 9, 10, 12, 30, 0), // Oct 10, 2024, 12:30 PM
      end: new Date(2024, 9, 10, 14, 0, 0), // Oct 10, 2024, 2:00 PM
      completed: false,
    },
    {
      title: "Workshop",
      start: new Date(2024, 9, 11, 9, 0, 0), // Oct 11, 2024, 9:00 AM
      end: new Date(2024, 9, 13, 17, 0, 0), // Oct 13, 2024, 5:00 PM (multi-day event)
      completed: false,
    },
    {
      title: "Vacation",
      start: new Date(2024, 9, 15, 0, 0, 0), // Oct 15, 2024, whole day
      end: new Date(2024, 9, 20, 23, 59, 59), // Oct 20, 2024, whole day (multi-day event)
      completed: false,
    },
    {
      title: "Doctor Appointment",
      start: new Date(2024, 9, 22, 16, 30, 0), // Oct 22, 2024, 4:30 PM
      end: new Date(2024, 9, 22, 17, 30, 0), // Oct 22, 2024, 5:30 PM
      completed: true,
    },
    {
      title: "Weekly Stand-up",
      start: new Date(2024, 9, 23, 10, 0, 0), // Oct 23, 2024, 10:00 AM (repeated event)
      end: new Date(2024, 9, 23, 10, 30, 0), // Oct 23, 2024, 10:30 AM
      completed: false,
    },
    {
      title: "Weekly Stand-up",
      start: new Date(2024, 9, 30, 10, 0, 0), // Oct 30, 2024, 10:00 AM
      end: new Date(2024, 9, 30, 10, 30, 0), // Oct 30, 2024, 10:30 AM
      completed: false,
    },
  ];
  return (
    <>
      <Navbar />
      <Header
        name='Calendar'
        showSearch={true}

        // exportOnClick={handleExportCSV}
        // addBtnClick={handleClickOpen}
      />
      <CalendarComponent data={data} />
    </>
  );
};

export default Calendar;
