import React from 'react';
import PhoneDisabledRoundedIcon from '@mui/icons-material/PhoneDisabledRounded';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton } from '@mui/material';

export const CallToast = ({ duration, endCall, isConnecting, muteCall, unmuteCall }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {isConnecting ? (
                <p style={{ animation: 'blinkingText 1s infinite' }}>Calling...</p>
            ) : (
                <p>{duration ? duration : '00:00:00'}</p>
            )}
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <IconButton onClick={muteCall} aria-label="mute" size='medium' >
                    <MicOffRoundedIcon fontSize='large' color='action' />
                </IconButton>
                <IconButton onClick={unmuteCall} aria-label="unmute" size='medium'>
                    <MicRoundedIcon fontSize='large'  color=''/>
                </IconButton>
                <IconButton onClick={endCall} aria-label="end call" size='medium' >
                    <PhoneDisabledRoundedIcon fontSize='large' color='warning' />
                </IconButton>
            </div>

            <style>{`
                @keyframes blinkingText {
                    0% { opacity: 1; }
                    50% { opacity: 0.5; }
                    100% { opacity: 1; }
                }
            `}</style>
        </div>
    );
};
