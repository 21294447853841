/** @format */

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import GridOverlayComp from "./GridOverlay";
import CustomLoadingOverlay from "./LoadingOverlay";
import CustomToolbar from "./CustomToolbar";
import {
  onPageChange,
  onSizeChange,
} from "../../../redux/actions/LayoutAction";

const escapeRegExp = (value) => {
  return value && value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    "& .MuiTablePagination-root": {
      marginBottom: "3rem",
    },
    "& .MuiTablePagination-selectLabel,& .MuiTablePagination-displayedRows,": {
      marginBottom: 0,
      color: "#495057",
    },
    "& .MuiTablePagination-toolbar": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiCheckbox-root.Mui-checked": {
      color: "#000",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#E1F3FA",
      border: "none !important",
      color: "#495057",
      "& .MuiDataGrid-columnSeparator": {
        color: "#4C6371",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "45 !important",
        maxHeight: "45 !important",
        lineHeight: "45 !important",
      },
    },
    "& .MuiButton-root": {
      color: "#495057",
      border: "2px solid #60859d",
      padding: "5px 10px",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)!important",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        minHeight: "45 !important",
        maxHeight: "45 !important",
        lineHeight: "45 !important",
      },
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        minHeight: "45 !important",
        maxHeight: "45 !important",
        lineHeight: "45 !important",
      },
    },
  },
}));

const Table = forwardRef(
  (
    {
      data,
      loading,
      apiRef,
      columns,
      hideFooter,
      checkboxSelection = false,
      ...otherProps
    },
    ref
  ) => {
    const { search } = useSelector((state) => state.commonStore);
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState(data || []);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const { rowCount, size } = useSelector((store) => store.layoutStore);
    const dispatch = useDispatch();
    const clasess = useStyles();

    useEffect(() => {
      const savedColumnVisibility =
        JSON.parse(localStorage.getItem("columnVisibility")) || {};
      setColumnVisibilityModel(savedColumnVisibility);
    }, []);

    useEffect(() => {
      const searchRegex = new RegExp(escapeRegExp(search), "gi");
      const filteredRows = data.filter((row) =>
        Object.keys(row).some((field) =>
          searchRegex.test(row[field] ? row[field].toString() : "")
        )
      );
      setRows(filteredRows);
    }, [search, data]);

    const handleColumnVisibilityChange = (model) => {
      setColumnVisibilityModel(model);
      localStorage.setItem("columnVisibility", JSON.stringify(model));
    };

    useEffect(() => {
      if (data) {
        setRows(data);
      }
    }, [data]);

    // Prepare data for CSV export
    const csvData = rows.map((row) => {
      const filteredRow = {};
      columns.forEach((col) => {
        const value = row[col.field];
        if (value !== null && typeof value !== "object") {
          filteredRow[col.field] = value;
        }
      });
      filteredRow.id = row.id || row.uuid;
      return filteredRow;
    });

    // Expose export function to parent component
    useImperativeHandle(ref, () => ({
      exportCSV: () => {
        const link = document.createElement("a");
        const blob = new Blob([JSON.stringify(csvData)], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "data-export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    }));

    return (
      <div className='table-container'>
        <div
          style={{
            height: !rows?.length ? "450px" : "auto",
            width: "100%",
          }}
          className='mt-3 table-wrapper'
        >
          <DataGrid
            apiRef={apiRef}
            className={`${clasess.root}`}
            {...otherProps}
            onPageSizeChange={(newPageSize) =>
              dispatch(onSizeChange(newPageSize))
            }
            onPageChange={(newPage) => dispatch(onPageChange(newPage))}
            rowsPerPageOptions={[20, 50, 100, 150].filter(
              (size) => rowCount >= size
            )}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: GridOverlayComp,
              Toolbar: CustomToolbar,
            }}
            rowCount={rowCount}
            paginationMode='server'
            getRowId={(row) => row?.id || row?.uuid}
            columns={columns}
            rows={rows}
            pageSize={size}
            checkboxSelection={checkboxSelection}
            loading={loading}
            rowHeight={40}
            pagination
            hideFooter={hideFooter}
            autoHeight={rows.length !== 0 ? true : false}
            sx={{ fontSize: "13px" }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
          />
        </div>
      </div>
    );
  }
);

export default Table;
