import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import EditComponent from "../../../../Layout/edit/EditComponent";

import AddQuoteForm from "./AddQuoteForm";
import useUpdateQuote from "./useUpdateQuote";
import {
  useDownloadQuote,
  useQuoteUpdate,
} from "../../../../../redux/actions/dealAction";
import getDomain from "../../../../../utils/getDomain";

const QuoteAccordion = ({ data }) => {
  const { QUOTE_UPDATE_INITIAL_FORM_STATE, QUOTE_FORM_VALIDATION } =
    useUpdateQuote(data);
  const { mutate } = useQuoteUpdate(data?.uuid);
  const { mutate: downloadPdf, downloadLoading } = useDownloadQuote({
    contact: data?.contact,
    quote: data?.uuid,
    domain: `${getDomain()}.leadmasters.in`,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12} className="d-flex align-items-center">
            <Grid item xs={6}>
              <Typography
                style={{ fontWeight: 500, color: "#313131" }}
                fontSize={"12px"}
                component="p"
                className="d-flex"
              >
                Quote ID - 1122009876
              </Typography>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-end">
              <IconButton sx={{ fontSize: "14px" }}>
                <span style={{ color: "#000000" }}>
                  <EditComponent
                    edit="Edit"
                    download="Download"
                    downloadLoading={downloadLoading}
                    heading="Update Quote"
                    showdelete={false}
                    showShare={true}
                    mutate={mutate}
                    share="Share"
                    onClickEdit=""
                    onclickDownload={downloadPdf}
                    onClickShare=""
                    initialValues={QUOTE_UPDATE_INITIAL_FORM_STATE}
                    validationSchema={QUOTE_FORM_VALIDATION}
                    Component={AddQuoteForm}
                  />
                </span>
              </IconButton>
            </Grid>
          </Grid>
          <Typography
            style={{ fontWeight: 500, color: "#313131" }}
            fontSize={"13px"}
            component="p"
            className="d-flex mt-1"
          >
            Quote Name :
            <span style={{ color: "#616161" }}>{data?.quote_title}</span>
          </Typography>
          <Typography
            style={{ fontWeight: 500, color: "#313131" }}
            fontSize={"13px"}
            component="p"
            className="d-flex mt-1"
          >
            Expiry Date :{" "}
            <span style={{ color: "#616161" }}>
              {moment(data?.expiry_date).format("DD/MM/YYYY")}
            </span>{" "}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default QuoteAccordion;
