import React, { useState } from "react";

import { Box, Menu } from "@mui/material";

const RightClickMenu = ({ name, children, isPhNum = false }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY + 1,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        handleClose,
        onClick: () => {
          if (child.props.onClick) {
            child.props.onClick();
            handleClose();
          }
        },
      });
    }
    return child;
  });

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu", width: isPhNum ? "auto" : "100%" }}
    >
      <Box onClick={isPhNum ? handleContextMenu : () => {}}>{name}</Box>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 7,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {childrenWithProps}
      </Menu>
    </div>
  );
};

export default RightClickMenu;
