import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { MdOutlineCall } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import useClickToCall from "../../../ivr/useClickToCall";
import useClickToCall from "../../../../ivr/useClickToCall";
import DeleteDialog from "../../../../utils/deleteDialog";

const PhoneContacts = () => {
  const { contact } = useSelector((state) => state.contactStore);
  const { mutate: primaryCall } = useClickToCall(contact?.primary_number);
  const { refetch: secondaryCall } = useClickToCall(contact?.secondary_number);
  const { refetch: workCall } = useClickToCall(contact?.work_number);
  return (
    <>
      {/* <div className="d-flex justify-content-between">
        <Typography
          color="text.dark"
          fontSize="12px"
          className="d-flex align-items-center"
        >
          <span style={{ fontWeight: "500" }} className="me-2">
            {contact.first_name}
          </span>{" "}
          {contact.title}, {contact.company_name}
        </Typography>
      </div> */}
      <div className='mt-2 d-flex justify-content-between'>
        <Typography
          color='text.dark'
          fontSize='12px'
          display='inline'
          className='ms-3'>
          Primary Number: {contact.primary_number}
        </Typography>
        {/* <Button
          variant="contained"
          size="small"
          style={{
            paddingInline: "8px",
            paddingBlock: "0px",
            fontSize: "12px",
            borderRadius: "26px",
          }}
          className="ms-4 text-capitalize"
        >
          <MdOutlineCall className="me-1" /> Call now
        </Button> */}
        <DeleteDialog
          getApiRefetch={() => {
            toast.promise(() => primaryCall(), {
              pending: "pending",
              success: "Call Connected!",
              error: "Sorry, something went wrong. Try again later.",
            });
          }}
          element={
            <Button
              variant='contained'
              size='small'
              style={{
                paddingInline: "8px",
                paddingBlock: "0px",
                fontSize: "12px",
                borderRadius: "26px",
              }}
              className='ms-4 text-capitalize'>
              <MdOutlineCall className='me-1' /> Call now
            </Button>
          }
          description='This will make a phone call on their primary number.'
          anotherAction='Call'
        />
      </div>
      <div className='mt-2 d-flex justify-content-between'>
        <Typography
          color='text.dark'
          fontSize='12px'
          display='inline'
          className='ms-3'>
          Secondary Number: {contact.secondary_number}
        </Typography>
        {/* <Button
          variant="contained"
          size="small"
          style={{
            paddingInline: "8px",
            paddingBlock: "0px",
            fontSize: "12px",
            borderRadius: "26px",
          }}
          className="ms-4 text-capitalize"
        >
          <MdOutlineCall className="me-1" /> Call now
        </Button> */}
        <DeleteDialog
          getApiRefetch={() => {
            toast.promise(() => secondaryCall(), {
              pending: "pending",
              success: "Call Connected!",
              error: "Sorry, something went wrong. Try again later.",
            });
          }}
          element={
            <Button
              variant='contained'
              size='small'
              style={{
                paddingInline: "8px",
                paddingBlock: "0px",
                fontSize: "12px",
                borderRadius: "26px",
              }}
              className='ms-4 text-capitalize'>
              <MdOutlineCall className='me-1' /> Call now
            </Button>
          }
          description='This will make a phone call on their primary number.'
          anotherAction='Call'
        />
      </div>
      <div className='mt-2 d-flex justify-content-between'>
        <Typography
          color='text.dark'
          fontSize='12px'
          display='inline'
          className='ms-3'>
          Work Number: {contact.work_number}
        </Typography>
        {/* <Button
          variant="contained"
          size="small"
          style={{
            paddingInline: "8px",
            paddingBlock: "0px",
            fontSize: "12px",
            borderRadius: "26px",
          }}
          className="ms-4 text-capitalize"
        >
          <MdOutlineCall className="me-1" /> Call now
        </Button> */}
        <DeleteDialog
          getApiRefetch={() => {
            toast.promise(() => workCall(), {
              pending: "pending",
              success: "Call Connected!",
              error: "Sorry, something went wrong. Try again later.",
            });
          }}
          element={
            <Button
              variant='contained'
              size='small'
              style={{
                paddingInline: "8px",
                paddingBlock: "0px",
                fontSize: "12px",
                borderRadius: "26px",
              }}
              className='ms-4 text-capitalize'>
              <MdOutlineCall className='me-1' /> Call now
            </Button>
          }
          description='This will make a phone call on their primary number.'
          anotherAction='Call'
        />
      </div>
    </>
  );
  // </Container>
};

export default PhoneContacts;
