const verifyDomain = () => {
  const domain = window.location.hostname;
  console.log('domaindomaindomaindomain', domain);
  if (domain.split(".").length === +process.env.REACT_APP_DOMAIN_CHECK) {
    return true;
  }

  return false;
};

export default verifyDomain;
