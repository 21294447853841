/** @format */
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import for navigation

const localizer = momentLocalizer(moment);

const MyCalendar = ({ data }) => {
  const [events, setEvents] = useState([]);
  const phone = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate(); // Initialize history for navigation

  useEffect(() => {
    const fetchedTasks = data
      .filter((task) => task !== null && task !== undefined)
      .map((task) => ({
        ...task,
        completed: task.completed || false, // Ensure completed property is present
      }));
    setEvents(fetchedTasks);
  }, [data]);

  const handleCheckboxChange = (event) => {
    const updatedEvents = events.map((task) =>
      task.title === event.title
        ? { ...task, completed: !task.completed }
        : task
    );
    setEvents(updatedEvents);
  };

  const handleEventClick = (event) => {
    // Navigate to deal page when the title is clicked
    navigate(`/deals/`); // Assuming each event has a unique id
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.completed ? "#4CAF50" : "#274c77 "; // Green for completed, pink for pending
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0",
        display: "flex",
        alignItems: "center",
        padding: "5px",
      },
    };
  };

  // Custom Event Component
  const EventComponent = ({ event }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        type='checkbox'
        checked={event.completed}
        onChange={() => handleCheckboxChange(event)}
        style={{ marginRight: "10px" }}
      />
      <span
        onClick={() => handleEventClick(event)}
        style={{ cursor: "pointer" }}
      >
        {event.title}
      </span>
    </div>
  );

  return (
    <div className={`calendar-container`}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        eventPropGetter={(event) => ({
          style: { backgroundColor: event.completed ? "#4fa34f" : "#ff5a5a" },
        })}
        className='custom-calendar'
        scrollToTime={new Date(2024, 9, 1, 9, 0, 0)}
        // eventPropGetter={eventStyleGetter}
        components={{ event: EventComponent }} // Use custom event component
      />
    </div>
  );
};

export default MyCalendar;
